/* @import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@400;700&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&family=Kaushan+Script&family=Source+Sans+Pro&display=swap'); */

.darkMode {
    background-color: #333333 !important;
    color: white !important;
}

.lightMode {
    background-color: #ffffff !important;
    color: black !important;
}

.ngx-pagination {
    background-color: white !important;
    color: black !important;
    box-shadow: none !important;
}

.centered-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* Adjust as needed */
    box-shadow: none;
}

.black-white-button {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.black-white-button:hover {
    background-color: black;
    color: white;
}

/* ================== error text ============== */
.error-text {
    font-style: italic;
    font-size: 15px;
}

.icon-texts {
    display: flex;
    align-items: center;
}

.back-icon {
    font-size: 30px;
}

.back-icon:hover {
    color: red;
}

/* card-border-radius */
.card-r-0 {
    border-radius: 0vh;
}

.card-r-1 {
    border-radius: 1.27vh;
}

.card-r-2 {
    border-radius: 3.8vh;
}

.card-r-3 {
    border-radius: 6.34vh;
}

.card-r-4 {
    border-radius: 7.6vh;
}

/* card-design */

.card-b-color {
    background-color: black;
}

.card-w-color {
    background-color: white;
}

.card-n-color {
    background-color: none;
}

/*  =================================* links */
.link-text {
    color: blue;
    font-style: italic;
}

/* =================================* inputs  */

.label-input {
    font-weight: 600;
    /* font-size: 1.52vh; */
    font-size: 12.4px;
    color: #6B7280;
    font-family: sans-serif;
}

.browser-default {
    /* padding: 3vh 2vh; */
    margin: 0.634vh 0vh;
    border: 0.15vh solid rgb(215, 215, 215);
}

.input-field input {
    padding-left: 1vh;
}

/* ================================ input ===================================== */




input[type=email],
input[type=text],
input[type=password],
input[type=number],
input[type=date] {
    /* height: 6.3vh; */
    height: 52.16px;
    /* padding: 2.9vh 2vh; */
    margin: 0.434vh 0vh;
    border-radius: 5px;
    /* display: inline-block; */
    /* border-radius: 0.2rem; */
    box-sizing: border-box;
    align-items: center;
    font-size: small;
    color: #17191d;
    background-color: #fff;
    border: 0.15vh solid rgb(215, 215, 215);
    outline: none !important;

}

/* placeholder */
input::placeholder {
    font-weight: 400;
    opacity: 0.5;
    color: gray;
    font-size: 0.9rem;
}

/* browser */
input.validate[type=text]:not(.browser-default) {
    border-bottom: 0.15vh solid rgb(215, 215, 215);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=email]:not(.browser-default) {
    border-bottom: 0.15vh solid rgb(215, 215, 215);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=password]:not(.browser-default) {
    border-bottom: 0.15vh solid rgb(215, 215, 215);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=number]:not(.browser-default) {
    border-bottom: 0.15vh solid rgb(215, 215, 215);
    box-shadow: 0 0vh 0 0 #4caf50;
}

/* focus browser */

input.validate[type=text]:not(.browser-default):focus {
    border-bottom: .25vh solid rgb(12, 12, 149);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=email]:not(.browser-default):focus {
    border-bottom: .25vh solid rgb(12, 12, 149);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=password]:not(.browser-default):focus {
    border-bottom: .25vh solid rgb(12, 12, 149);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=number]:not(.browser-default):focus {
    border-bottom: .25vh solid rgb(12, 12, 149);
    box-shadow: 0 0vh 0 0 #4caf50;
}

input.validate[type=date]:not(.browser-default):focus {
    border-bottom: .25vh solid rgb(12, 12, 149);
    box-shadow: 0 0vh 0 0 #4caf50;
}

/* focus new  */


input[type=text]:focus {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

input[type=email]:focus {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

input[type=password]:focus {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

input[type=number]:focus {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

input[type=date]:focus {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

/* ================================ text area ===================================== */
textarea {
    padding: 3vh 2vh;
    margin: 0.634vh 0vh;
    box-sizing: border-box;
    align-items: center;
    font-size: small;
    color: #17191d;
    background-color: #fff;
    border: 0.15vh solid rgb(215, 215, 215);
}


textarea:focus {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

textarea:hover {
    background-color: white;
    border: .25vh solid rgb(12, 12, 149);
}

textarea:not(:checked)+span:before {
    border: 0.25vh solid #393939;
    padding: 0.89vh;
    animation: ripple 0.2s linear forwards;
}

@media(max-width: 600px) {

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number] {
        /* padding: 2.6vh 2.3vh; */
        height: 6.3vh;
    }
}


/*  =================================* card-setting */
/* =====================================card Options */

.card-yes {
    box-shadow: none;
    background-color: white;
    padding-top: 0vh;
}

.card-yes .border-content {
    border-radius: 0vh;
}

.card-panel {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}



.card-panel2 .border-content2 {
    box-shadow: rgba(0, 0, 0, 0.1) 0vh 0vh 0.38vh 0vh, rgba(0, 0, 0, 0.1) 0vh 0vh 0.13vh 0vh;
    overflow: hidden;
    border-radius: 0.51vh;
    margin-top: 0.89vh;
}

.card-panel2 .border-content3 {
    box-shadow: rgba(0, 0, 0, 0.1) 0vh 0vh 0.38vh 0vh, rgba(0, 0, 0, 0.1) 0vh 0vh 0.13vh 0vh;
    overflow: scroll;
    max-height: 38.02vh;
    border-radius: 0.51vh;
    margin-top: 0.89vh;
}

/*  =================================* font family */
div {
    font-family: sans-serif;
    color: #4b5563;
}


/*  =================================* font size */

.title-member {
    color: #001e00;
    font-weight: 600;
}

/*  ============* title */
.title-section {
    font-size: 33.089px;
}

.title-section1 {
    font-size: 26.08px;
}

.title-section2 {
    font-size: 24.776px
}

.title-section3-2 {
    font-size: 20px
}


.title-section3 {
    font-size: 18.581999999999997px;
}

.title-section4 {

    font-size: 17.5225px;
}

.title-section4-4 {
    font-size: 17px;
}

.title-section5 {
    font-size: 33.089px;
}

/*  ==========* sub-title */
.sub-title-section {
    font-size: 20.6195px;
}

.sub-title-section1 {
    font-size: 16.6195px;
}

.sub-title-section2 {
    font-size: 14.4255px;
}

.sub-title-section3 {
    font-size: 13.4475px;
}

/*  ==========* description */

.description-section {
    font-size: 12.388px;
}

.description-section2 {
    font-size: 10.3505px;
}

.description-section3 {
    font-size: 8.2315px;
}

/*  =================================* font size == small screens */
/*  =================================* font weight */
.bold-900 {
    font-weight: 900;
}

.bold-800 {
    font-weight: 800;
}

.bold-700 {
    font-weight: 700;
}

.bold-600 {
    font-weight: 600;
}

.bold-500 {
    font-weight: 500;
}

.bold-400 {
    font-weight: 400;
}

.bold-300 {
    font-weight: 300;
}

.bold-200 {
    font-weight: 200;
}

.bold-100 {
    font-weight: 100;
}

/*  =================================* container */

.container1 {
    padding: 0vh 0vh;
}

.container2 {
    padding: 5.07vh 5.07vh;
}

.container3 {
    padding: 7.6vh 7.6vh;
}

.container4 {
    padding: 10.14vh 10.14vh;
}

.container5 {
    padding: 8.87vh 25.35vh;
}

.container6 {
    padding: 0vh 7.6vh;
}

.container7 {
    padding: 6.34vh 6.34vh 63.37vh 6.34vh;
}

.container8 {
    padding: 0vh 0vh 6.34vh 0vh;
}

.container9 {
    padding: 11.4vh 0vh 11.4vh 0vh;
}

.container10 {
    padding: 0vh 2.27vh 0vh 12.53vh;
}

.container11 {
    padding: 0vh 2.53vh 0vh 15.21vh;
}

.container12 {
    padding: 5vh 15vh;
}

.container-bottom {
    margin-bottom: 38.02vh;
}

.container-pi {
    padding: 0vh 10vh 10vh 5vh;
}

/*  =================================* scroller  */

::-webkit-scrollbar {
    width: 1.3vh;

}

::-webkit-scrollbar-thumb {
    background: rgba(31, 30, 30, 0.509);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}




/*  =================================* divider  */

/*  =================================* no image / long-body  */
.long-body-snippet {
    overflow: hidden;
    line-height: 1.3;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
}

/*  =================================* image /short-body  */

.short-body-snippet {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

/*  =================================* image /short-body  */

.middle-body-snippet {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/*  =================================* image /short-body  */
.mid-body-snippet {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/*  =================================* Button Type */
.roundButton {
    border-radius: 5.70vh;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;

}

.curvedButton {
    border-radius: 0.634vh;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
}

.rectangularButton {
    border-radius: 0vh;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
}

/*  =================================* Button card Type */


.roundCardButton {
    border-radius: 100vh;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;



}

.curvedCardButton {
    border-radius: 1vh;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;


}

.rectangularCardButton {
    border-radius: 0vh;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;


}


.hexaCardButton {
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
    background-color: #ffffff;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

}

.noCardButton {
    background-color: transparent !important;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: small;
    box-shadow: none;
}

/*  =================================* Icon card Type */


.roundCardIcon {
    border-radius: 100vh;
    border: 2px solid;
}

.curvedCardIcon {
    border-radius: 1vh;
    border: 2px solid;
}

.rectangularCardIcon {
    border-radius: 0vh;
    border: 2px solid;
}


.hexaCardIcon {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    border: 2px solid;
}

.noCardIcon {
    box-shadow: none;
    border: 0px solid;
}

/*  =================================* Icon size Type */
.small-t {
    font-size: 1.0em;
}

.medium-t {
    font-size: 1.2em;
}

.large-t {
    font-size: 1.4em;
}





.add-button {
    border-radius: 0.634vh;
    border: 0.19vh solid;
    box-shadow: none;
    text-align: center;
    font-size: 13.4475px;
    width: 206.1px;
    font-weight: 600;
    display: flex;
}

.delete-button {
    background-color: #CF3F41;
    width: 206.1px;
    color: #FFF;
}

.edit-button {
    background-color: #677C96;
    width: 206.1px;
    color: #FFF;
}


.save-button {
    background-color: #4260E6;
    width: 206.1px;
    color: #FFF;
}

.update-button {
    width: 206.1px;
    color: #000;
    background-color: white;
    border: 0.25vh solid black;
}

.cancel-button {
    width: 206.1px;
    color: #FFF;
    background-color: none;

}

.back-button {
    border: none;
    background-color: white;
    color: black;
    box-shadow: none;
    text-align: center;
}

.publish-button {
    background-color: rgb(45, 100, 233);
    width: 206.1px;
    color: #FFF;
}

.backed-button {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

/*  =================================* button placement */
.b-u-c {
    align-items: center;
}

.b-u-l {
    align-items: left;
}

.b-u-r {
    align-items: right;
}

/*  =================================* border-width */
.b-w {
    border: 0vh solid
}

.b-w-1 {
    border: 0.13vh solid
}

.b-w-2 {
    border: 0.25vh solid
}

.b-w-3 {
    border: 0.38vh solid
}

.b-w-4 {
    border: 0.51vh solid
}

.b-w-5 {
    border: 0.634vh solid
}

.b-w-6 {
    border: 0.76vh solid
}

.b-w-7 {
    border: 0.89vh solid
}

.b-w-8 {
    border: 1.01vh solid
}

.b-w-9 {
    border: 1.14vh solid
}

.b-w-10 {
    border: 1.27vh solid
}

/*  =================================* slider  */

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    width: 65%;
    padding: 10vh;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    width: 60%;
    padding: 10vh;

}

.Center-k {
    position: absolute;
    top: 25%;
    left: 25%;
    bottom: 25%;
    right: 25%;
    text-align: center;
    width: 60%;
}

.top-left {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    width: 60%;
    padding: 5vh;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    width: 60%;
    padding: 5vh;
}

/*  =================================* border-radius */
.b-n {
    border: none;
}

.b-n-1 {
    border-radius: 1.27vh;
}

.b-n-2 {
    border-radius: 2.53vh;
}

.b-n-3 {
    border-radius: 3.8vh;
}

.b-n-4 {
    border-radius: 5.07vh;
}

.b-n-5 {
    border-radius: 6.34vh;
}

.b-n-6 {
    border-radius: 7.6vh;
}

.b-n-7 {
    border-radius: 8.87vh;
}

.b-n-8 {
    border-radius: 10.14vh;
}

.b-n-9 {
    border-radius: 11.4vh;
}

.b-n-10 {
    border-radius: 12.67vh;
}

/*  =================================* border-shadow */
.b-s {
    box-shadow: none;
}

.b-s-1 {
    box-shadow: rgba(100, 100, 111, 0.2) 0vh 0.13vh 0.13vh 0vh;
}

.b-s-2 {
    box-shadow: rgba(100, 100, 111, 0.2) 0 0vh.89vh 2.53vh 0vh;
}

/*  =================================* padding */
.p-t-0 {
    padding-top: 0vh;
}

.p-t-1 {
    padding-top: 1.27vh;
}

.p-t-1-2 {
    padding-top: 1.9vh;
}

.p-t-2 {
    padding-top: 2.53vh;
}

.p-t-3 {
    padding-top: 3.8vh;
}

.p-t-4 {
    padding-top: 5.07vh;
}

.p-t-5 {
    padding-top: 6.34vh;
}

.p-t-6 {
    padding-top: 7.6vh;
}

.p-t-7 {
    padding-top: 8.87vh;
}

.p-t-8 {
    padding-top: 10.14vh;
}

.p-t-9 {
    padding-top: 11.4vh;
}

.p-t-10 {
    padding-top: 12.67vh;
}

.p-t-15 {
    padding-top: 19.01vh;
}

.p-t-25 {
    padding-top: 31.69vh;
}

.p-t-35 {
    padding-top: 35vh;
}

.p-t-45 {
    padding-top: 45vh;
}

.p-t-55 {
    padding-top: 250px;
}

.p-b-0 {
    padding-bottom: 0vh;
}

.p-b-1 {
    padding-bottom: 1.27vh;
}

.p-b-2 {
    padding-bottom: 2.53vh;
}

.p-b-3 {
    padding-bottom: 3.8vh;
}

.p-b-4 {
    padding-bottom: 5.07vh;
}

.p-b-5 {
    padding-bottom: 6.34vh;
}

.p-b-6 {
    padding-bottom: 7.6vh;
}

.p-b-7 {
    padding-bottom: 8.87vh;
}

.p-b-8 {
    padding-bottom: 10.14vh;
}

.p-b-9 {
    padding-bottom: 11.4vh;
}

.p-b-10 {
    padding-bottom: 12.67vh;
}

.p-l-0 {
    padding-left: 0vh;
}

.p-l-1 {
    padding-left: 1.27vh;
}

.p-l-2 {
    padding-left: 2.53vh;
}

.p-l-3 {
    padding-left: 3.8vh;
}

.p-l-4 {
    padding-left: 5.07vh;
}

.p-l-5 {
    padding-left: 6.34vh;
}

.p-l-6 {
    padding-left: 7.6vh;
}

.p-l-7 {
    padding-left: 8.87vh;
}

.p-l-8 {
    padding-left: 10.14vh;
}

.p-l-9 {
    padding-left: 11.4vh;
}

.p-l-10 {
    padding-left: 12.67vh;
}

.p-l-12 {
    padding-left: 15vh;
}

.p-r-0 {
    padding-right: 0vh;
}

.p-r-1 {
    padding-right: 1.27vh;
}

.p-r-2 {
    padding-right: 2.53vh;
}

.p-r-3 {
    padding-right: 3.8vh;
}

.p-r-4 {
    padding-right: 5.07vh;
}

.p-r-5 {
    padding-right: 6.34vh;
}

.p-r-6 {
    padding-right: 7.6vh;
}

.p-r-7 {
    padding-right: 8.87vh;
}

.p-r-8 {
    padding-right: 10.14vh;
}

.p-r-9 {
    padding-right: 11.4vh;
}

.p-r-10 {
    padding-right: 12.67vh;
}


.no-padding {
    padding: 0vh 0vh 0vh 0vh;
}

/* margin-top  */
.no-margin {
    margin: 0vh 0vh 0vh 0vh;
}

.p-m-0 {
    margin-top: 0vh;
}

.p-m-1 {
    margin-top: 1.27vh;
}

.p-m-2 {
    margin-top: 2.53vh;
}

.p-m-3 {
    margin-top: 3.8vh;
}

.p-m-4 {
    margin-top: 5.07vh;
}

.p-m-5 {
    margin-top: 6.34vh;
}

.p-m-6 {
    margin-top: 7.6vh;
}

.p-m-7 {
    margin-top: 8.87vh;
}

.p-m-8 {
    margin-top: 10.14vh;
}

.p-m-9 {
    margin-top: 11.4vh;
}

.p-m-10 {
    margin-top: 12.67vh;
}

.p-m-15 {
    margin-top: 19.01vh;
}

.p-m-50 {
    margin-top: 63.37vh;
}

/* margin-bottom  */

.b-m-0 {
    margin-bottom: 0vh;
}

.b-m-1 {
    margin-bottom: 1.27vh;
}

.b-m-2 {
    margin-bottom: 2.53vh;
}

.b-m-3 {
    margin-bottom: 3.8vh;
}

.b-m-4 {
    margin-bottom: 5.07vh;
}

.b-m-5 {
    margin-bottom: 6.34vh;

}

.b-m-6 {
    margin-bottom: 7.6vh;
}

.b-m-7 {
    margin-bottom: 8.87vh;
}

.b-m-8 {
    margin-bottom: 10.14vh;
}

.b-m-9 {
    margin-bottom: 11.4vh;
}

.b-m-10 {
    margin-bottom: 12.67vh;
}

.b-m-15 {
    margin-bottom: 19.01vh;
}

.ll-m-5 {
    margin-left: 5vh;
}

.rr-m-5 {
    margin-right: 5vh;
}

/*  =================================* read more */
.read-more {
    margin: 5% 5% 5% 2%;
    bottom: 0vh;
    left: 122.81vh;
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
}

/*  =================================* border */

.content-media {
    width: 100%;
    height: 44.36vh;
    padding: 2% 2%;
}

/*  =================================* card-types */
.card-for-content {
    border-radius: 0.51vh;
}

.card-for-landing {
    border-radius: 0.51vh;
}

/*  =================================*Admin card title */
.card-title-fixed {
    position: fixed;
    height: 20vh;
}

.title-nation h4 {
    color: #4b5563;
    font-size: 14.4255px;
    font-weight: bold;
    font-family: sans-serif;
}

.title-nation h6 {
    color: #4b5563;
    font-size: 14.4255px;
    font-weight: bold;
    font-family: sans-serif;
}

.title-nation h5 {
    color: #292929;
    font-size: 17.6855px;
    font-weight: 700;
    font-family: sans-serif;
}

.header-title h6 {
    color: #4b5563;
    font-size: 27.872999999999998px;
    font-weight: 700;
    font-family: 'anton';
}

/* display more  */

.seeAll-displayName {

    color: teal;
    font-style: italic;
    font-family: sans-serif;
    font-size: 15.485px;
}

/*  =================================* color chooser button */

.color-chooser-btn {
    background-color: #f5f5f5;
    width: 100%;
    margin: 1.27vh 1.27vh;
}

/*  =================================*Public title */
.header-title h1 {
    /* font-size: 3.8vh; */
    font-size: 30.97px;
    font-family: 'Anton';
    padding-top: 3.8vh;
    padding-bottom: 2.53vh;
}

/*  =================================*Public title */
.icon-text {
    display: flex;
    cursor: pointer;
}


.switch label input[type="checkbox"]:checked+.lever {
    background-color: rgb(0, 138, 246);
}

.switch label .lever {
    background-color: rgb(216, 45, 66);
}

.switch label .lever:after {}

.switch label input[type=checkbox]:checked+.lever::after {
    background-color: #ffffff;
}

/* =======================================contentsssssssss */
.body-h {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.header-setting {
    flex: 0 0 auto;
    padding: 0vh 0vh 0vh 0vh;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 6.34vh;
    font-family: sans-serif;
    border-bottom: 0.25vh solid rgb(233, 234, 244);
    background-color: white;
    z-index: 1;
}

.header-setting h4 {
    color: #393939;
    font-size: 21px;
    font-weight: 500;
    background-color: white;
    font-family: 'Inter', sans-serif;
    margin-bottom: 0;
}

.header-setting p {
    color: #393939;
    background-color: white;
    font-family: 'Golos Text', sans-serif;
    font-family: 'Inter', sans-serif;
    padding: 0;
}

.footer-setting {
    position: fixed;
    bottom: 0;
    width: 120vh;
    padding: 0.634vh 0vh 0vh 1.27vh;
    font-family: sans-serif;
    background-color: rgb(243, 244, 245);
}

.footer-setting a {
    justify-content: space-between;
}

.edit-name {
    font-size: 20px;
}

.StickyContent {
    flex: 0 0 auto;
    padding: 2.53vh 0vh 0vh 2.53vh;
}

/* ======================dropdown */
/* there used */
#dropdown-header {
    box-shadow: none;
    width: 30vh;
    position: absolute;
    top: -6.6vh;
    opacity: 1;
    display: none;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

#dropdown-header li a {
    color: #111;
    font-size: 12px;
    border-bottom: 0.5px solid rgba(248, 245, 245, 0.493);
}


.backed-button {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}


/* ========= */

.Contain {
    background-size: contain;
    background-repeat: no-repeat;
}

.Cover {
    background-size: cover;
}

.drag-handle {
    cursor: move;
    padding: 5px;
}


.drag-handle:hover {
    color: rgb(206, 142, 142) !important;
    font-size: 25px !important;
}



/* ========= */

[type="checkbox"].filled-in:checked+span:not(.lever):after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #60A1F2;
    background-color: #60A1F2;
    z-index: 0;
}
/* =============== collection ======= */


/* Lists & Collections */
.collection {
    border-radius: 8px;
    overflow: hidden;
}

.collection-header {
    /* background: #00796b; */
    color: rgb(0, 0, 0);
    font-size: 15px;
    padding: 0px 15px;
    border-radius: 8px 8px 0 0;
}

.collection-item {
    padding: 5px 12px;
    border-bottom: 1px solid #eee;
}

.collection-item a {
    color: #393939;
    font-weight: bold;
}

@media(max-width: 600px) {

    .drag-handle {
        font-size: 25px;
    }


    ::-webkit-scrollbar {
        width: 0.93vh;
        border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(31, 30, 30, 0.509);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }

    .container10 {
        padding: 0vh 0vh 0vh 2.53vh;
    }

    .card-yes .border-content {
        height: 92.5vh;
        border: 0vh solid;
        overflow: scroll;
        border-radius: 0vh;
        margin-top: 1.9vh;
        margin-left: 0vh;
    }

    .footer-setting {
        position: fixed;
        bottom: 0;
        width: 90%;
        padding: 1.27vh 0vh 1.27vh 1.27vh;
        border-top: 0.063vh solid rgb(218, 214, 214);
        font-family: sans-serif;
        border: 0.25vh solid blue;
    }

    .header-setting {
        padding: 0vh 0vh 3vh 0vh;
    }

    .StickyContent {
        padding: 0% 1% 0% 0%;
    }


    .footer-setting a {
        margin-bottom: 1.27vh;
    }


    .container7 {
        padding: 0vh;
    }

    .container11 {
        padding: 0vh 2vh 0vh 2vh;
    }

    .color-chooser-btn {
        background-color: #f5f5f5;
        width: 100%;
    }

    .container-pi {
        padding: 0vh;
    }

}